import { initializeApp } from "firebase/app";
import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config';
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import { APIKEY, APPID, AUTHDOMAIN, MEASUREMENTID, MESSAGINGSENDERID, PROJECTID, STORAGEBUCKET } from "../utils/contants";
import pkg from "../../app.json";

const firebaseConfig = {
	apiKey: APIKEY,
	authDomain: AUTHDOMAIN,
	projectId: PROJECTID,
	storageBucket: STORAGEBUCKET,
	messagingSenderId: MESSAGINGSENDERID,
	appId: APPID,
	measurementId: MEASUREMENTID,
};

// Definindo a interface para propriedades do usuário
interface UserProperties {
	[key: string]: string;
}

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const remoteConfig = getRemoteConfig(app);

// Configurar tempos de timeout e intervalo mínimo
remoteConfig.settings = {
	fetchTimeoutMillis: 60000, // Tempo limite de 60 segundos
	minimumFetchIntervalMillis: 3600000 // Intervalo mínimo de 1 hora entre as buscas
};

// Função para buscar e ativar as configurações remotas com tratamento de erros
const fetchAndActivateRemoteConfig = async () => {
	try {
		const activated = await fetchAndActivate(remoteConfig);
		if (activated) {
			console.log('Remote config values were activated');
		} else {
			console.log('Remote config values were already activated');
		}
	} catch (error) {
		console.error('Failed to fetch and activate remote config:', error);
	}
};

// Chamar a função para buscar e ativar as configurações
fetchAndActivateRemoteConfig().catch(()=> {})

// Função para registrar eventos personalizados
export const logCustomEvent = async (eventName: string, eventParams: Record<string, any>): Promise<void> => {
	logEvent(analytics, eventName, eventParams);
};

// Função para registrar propriedades do usuário
export const SetUserProperties = async () => {
	setUserProperties(analytics, { app_version: pkg?.expo?.version });
	logEvent(analytics, 'app_version', { version: pkg?.expo?.version });
};
